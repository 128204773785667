<template>
  <div>
    <el-descriptions class="margin-top" title="基本信息" :column="3" border>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          登录人
        </template>
        {{ UserName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          剩余版权
        </template>
        {{ sysInfoData.copyright }} 天
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          创建日期
        </template>
        {{ sysInfoData.creatDate }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          状态
        </template>
        <el-tag v-if="sysInfoData.type === 0" size="small" type="danger">未授权</el-tag>
        <el-tag v-if="sysInfoData.type === 1" size="small" type="success">正常</el-tag>
        <el-tag v-if="sysInfoData.type === 2" size="small" type="warning">异常</el-tag>
        <el-tag v-if="sysInfoData.type === 3" size="small" type="danger">盗版</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          项目名称
        </template>
        {{ sysInfoData.project }}
      </el-descriptions-item>
    </el-descriptions>
    <el-row style="margin-top: 20px">
      <el-col :span="7">
        <el-card shadow="hover">
          今日访问量：{{ count.toDayCount }}
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          本月访问量：{{ count.monthCount }}
        </el-card>
      </el-col>
      <el-col :span="9">
        <el-card shadow="hover">
          总访问量：{{ count.allCount }}
        </el-card>
      </el-col>
    </el-row>
    <el-card class="box-card" shadow="hover" style="margin-top: 20px">
      <span>2022年7月21日更新</span>
      <ul>
        <li>新增功能：文章排序（测试）。</li>
        <li>1、选择模块</li>
        <li>2、点击"排序"</li>
        <li>3、排序好后点击"确定"</li>
      </ul>
      <span>历史更新</span>
      <ul>
        <li>优化：数据安全，复杂化加密算法。</li>
        <li>BUG修复：访问记录显示异常。</li>
        <li>优化：留言按留言时间顺序排序。</li>
        <li>优化：新闻按时间顺序排序。</li>
        <li>新增功能1：留言联系人信息。</li>
        <li>新增功能2：首页轮播大图模块增加。</li>
        <li>关闭功能：新增艺术家模块因BUG暂时关闭。</li>
        <li>新增功能1：艺术家作品的新增、修改和删除</li>
        <li>新增功能2：首页滚动图片的修改（替换）</li>
      </ul>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "dynamic",
  data() {
    return {
      UserName: window.localStorage.getItem('UserName').toUpperCase(),
      sysInfoData: {},
      count: {}
    }
  },
  mounted() {
    this.getSysInfo(this.UserName)
    this.getCount()
  },
  methods: {
    getSysInfo(userName) {
      this.$http.get('/api/sysInfo/getSysInfo/' + userName).then((res)=>{
        this.sysInfoData = res.data.data
      })
    },
    getCount() {
      this.$http.get('/api/visitor/getCount').then((res)=>{
        this.count = res.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>